import axios from "axios";
import { useState } from "react";

const useSupportingDocumentUpdate = () => {
	// Variables
	const [
		supportingDocumentUpdateResponse,
		setSupportingDocumentUpdateResponse,
	] = useState(null);

	// Function to update a specific supporting document
	const supportingDocumentUpdate = async (id, data) => {
		try {
			const response = await axios.post(`supporting-document/update/${id}`, data);
			setSupportingDocumentUpdateResponse(response);
		} catch (error) {
			const status = error.response?.status || 500;
			setSupportingDocumentUpdateResponse({
				status,
			});
		}
	};

	return { supportingDocumentUpdate, supportingDocumentUpdateResponse };
};

export default useSupportingDocumentUpdate;
