import axios from "axios";
import { useState } from "react";

const useSupportingDocumentCreate = () => {
  // Variables
  const [
    supportingDocumentCreateResponse,
    setSupportingDocumentCreateResponse,
  ] = useState(null);

  // Function to create supporting document
  const supportingDocumentCreate = async (data) => {
    try {
      const response = await axios.post(`supporting-document/create`, data);
      setSupportingDocumentCreateResponse(response);
    } catch (error) {
      const status = error.response?.status || 500;
      setSupportingDocumentCreateResponse({
        status,
      });
    }
  };
  return { supportingDocumentCreate, supportingDocumentCreateResponse };
};

export default useSupportingDocumentCreate;
