const Article = ({ article }) => {

	const stripHtmlTags = (html) => {
		const div = document.createElement("div");
		div.innerHTML = html;
		return div.textContent || div.innerText || "";
	};

	return (
		<div
			className="text-white bg-primary rounded-lg p-4"
		>
			<img
				src={`/images/${article.image}`}
				alt={article.title}
				className="mx-4"
				style={{ width: '60px' }}
			/>
			<h2 className="mx-4 pt-4 font-bold">
				{article.title.replace(/_/g, ' ')}
			</h2>
			<div className="text-sm p-4">
				{stripHtmlTags(article.body).substring(0, 130)}..
			</div>
		</div>
	);
};

export default Article;