import axios from "axios";
import { useState } from "react";

const useExpenseUpdate = () => {
  // Variables
  const [expenseUpdateResponse, setExpenseUpdateResponse] = useState(null);
  // Function to update expense in db
  const expenseUpdate = async (id, data) => {
    if (data.price.currency != "EUR")
      data.price.currency = "EUR";
    try {
      const response = await axios.patch(`expense/update/${id}`, data);
      if (!response.data.expense.date || !response.data.expense.merchant.name || response.data.expense.price.amount == 0 || !response.data.expense.price.currency) {
        setExpenseUpdateResponse({ status: 500 });
      } else {
        setExpenseUpdateResponse(response);
      }
    } catch (error) {
      setExpenseUpdateResponse({
        status: error.response?.status || 500,
      });
    }
  };
  return { expenseUpdate, expenseUpdateResponse };
};

export default useExpenseUpdate;
