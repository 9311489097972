import { useState } from "react";
import axios from "axios";

const useExpenseDelete = () => {
    const [expenseDeleteResponse, setExpenseDeleteResponse] = useState(null);

    // Function to delete an expense from the database
    const expenseDelete = async (expenseId) => {
        try {
            const response = await axios.delete(`expense/delete/${expenseId}`);
            setExpenseDeleteResponse(response);
        } catch (error) {
            setExpenseDeleteResponse(error);
        }
    };

    return { expenseDelete, expenseDeleteResponse };
};

export default useExpenseDelete;
