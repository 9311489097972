import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { triggerAside } from "../actions/asideAction";
import { useDispatch } from "react-redux";
import Container from "../components/layout/Container";

const PageTitle = ({ title }) => {
  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(triggerAside());
  };
  return (
    <Container>
      <div className="flex items-center gap-2">
        <span
          className="inline-block sm:hidden md:hidden lg:hidden"
          onClick={handleOnClick}
        >
          <FontAwesomeIcon icon={faBars} />
        </span>
        <h1 className="text-2xl font-semibold">{title}</h1>
        <div className="border-b border-gray-300"></div>
      </div>
    </Container>
  );
};

export default PageTitle;
