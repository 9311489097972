import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { openModal, closeModal } from "../../actions/modalActions";
import Modal from "../elements/Modal";
import ReceiptUploadForm from "./ReceiptUploadForm";
import useExpenseCreate from "../../actions/hooks/useExpenseCreate";
import useExpenseUpdate from "../../actions/hooks/useExpenseUpdate";
import useExpenseDelete from "../../actions/hooks/useExpenseDelete";
import useSupportingDocumentCreate from "../../actions/hooks/useSupportingDocumentCreate";
import useSupportingDocumentAnalyze from "../../actions/hooks/useSupportingDocumentAnalyze";
import Alert from "../elements/Alert";
import useShowAlert from "../../actions/hooks/utility/useShowAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setRerender } from "../../actions/rerenderAction";
import Compressor from "compressorjs";
import { useNavigate } from "react-router-dom";
import { startLoader } from "../../actions/loaderActions";

export default function ExpenseCreateOptionScan({ children }) {
	const dispatch = useDispatch();
	const [receipt, setReceipt] = useState(null);
	const [loading, setLoading] = useState(false);
	const [expenseId, setExpenseId] = useState(null);
	const [showFileSizeAlert, setShowFileSizeAlert] = useState(false);
	const [showAnalysisErrorAlert, setShowAnalysisErrorAlert] = useState(false);
	const [showUnsupportedFileTypeAlert, setShowUnsupportedFileTypeAlert] = useState(false);
	const [quality, setQuality] = useState(1);
	const navigate = useNavigate();

	// Handle Open Modal to upload Receipt
	const handleOpenModal = () => {
		dispatch(
			openModal({
				modalType: "static",
				modalTitle: "New Expense",
				key: "modalReceiptSubmit",
				needsDelete: false,
			})
		);
	};

	const modal = useSelector((store) => store.modal);

	const uploadReceiptImage = (file) => {
		const supportedFormats = ['image/jpeg', 'image/png'];

		// Check for supported formats
		if (!supportedFormats.includes(file.type)) {
			setShowUnsupportedFileTypeAlert(true);
			setReceipt(null);
			dispatch(closeModal());
			return null;
		}

		// Set the receipt without compression initially
		setReceipt(file);
	};

	// useEffect to reset the unsupported file alert
	useEffect(() => {
		if (showUnsupportedFileTypeAlert) {
			const timer = setTimeout(() => {
				setShowUnsupportedFileTypeAlert(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [showUnsupportedFileTypeAlert]);

	// useEffect to reset analysis error alert
	useEffect(() => {
		if (showAnalysisErrorAlert) {
			const timer = setTimeout(() => {
				setShowAnalysisErrorAlert(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [showAnalysisErrorAlert]);

	// useEffect to reset file size error alert
	useEffect(() => {
		if (showFileSizeAlert) {
			const timer = setTimeout(() => {
				setShowFileSizeAlert(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [showFileSizeAlert]);

	const { expenseCreate, expenseCreateResponse } = useExpenseCreate();
	const { supportingDocumentCreate, supportingDocumentCreateResponse } = useSupportingDocumentCreate();
	const { supportingDocumentAnalyze, supportingDocumentAnalyzeResponse } = useSupportingDocumentAnalyze();
	const { expenseUpdate, expenseUpdateResponse } = useExpenseUpdate();
	const { expenseDelete } = useExpenseDelete();
	const { showSuccessAlert, setShowSuccessAlert } = useShowAlert();

	const modalSubmit = () => receipt && expenseCreate();

	// Reset state when modal closes
	useEffect(() => {
		if (!modal.isOpen) {
			setReceipt(null);
			setQuality(1);
		}
	}, [modal.isOpen]);

	// Function to compress and create document iteratively
	const compressAndCreateDocument = (file, quality) => {
		// Ensure quality does not fall below 0.1
		if (quality < 0.1) {
			expenseDelete(expenseId);
			setLoading(false);
			dispatch(closeModal());
			setShowFileSizeAlert(true);
			dispatch(setRerender());
			return;
		}

		new Compressor(file, {
			quality: quality,
			maxWidth: 800,
			maxHeight: 800,
			success: (compressedFile) => {
				let formData = new FormData();
				formData.append("file", compressedFile);
				formData.append("name", compressedFile.name);
				formData.append("expense_id", expenseCreateResponse.data.expense.id);

				setExpenseId(expenseCreateResponse.data.expense.id);
				supportingDocumentCreate(formData);
			},
			error: () => {
				setShowFileSizeAlert(true);
				setLoading(false);
			},
		});
	};

	// Create supporting document
	useEffect(() => {
		if (expenseCreateResponse && receipt) {
			setLoading(true);
			// Initially start with full quality (no compression)
			const formData = new FormData();
			formData.append("file", receipt);
			formData.append("name", receipt.name);
			formData.append("expense_id", expenseCreateResponse.data.expense.id);

			setExpenseId(expenseCreateResponse.data.expense.id);
			supportingDocumentCreate(formData);
		}
	}, [expenseCreateResponse]);

	// Handle document creation response and retry with reduced quality if necessary
	useEffect(() => {
		if (supportingDocumentCreateResponse) {
			setLoading(true);
			const status = supportingDocumentCreateResponse.status;
			if (status === 200) {
				// Document creation was successful, proceed to analysis
				supportingDocumentAnalyze(supportingDocumentCreateResponse.data.supportingDocument.id);
			} else {
				// Retry with lower quality if creation fails
				compressAndCreateDocument(receipt, quality - 0.1);
				setQuality(quality - 0.1);
			}
		}
	}, [supportingDocumentCreateResponse]);

	// Analyze supporting document
	useEffect(() => {
		if (supportingDocumentAnalyzeResponse) {
			const receiptData = supportingDocumentAnalyzeResponse?.data?.data?.original?.receipt_data;
			if (receiptData?.length) {
				expenseUpdate(expenseId, receiptData[0]);
			} else {
				expenseDelete(expenseId);
				setLoading(false);
				dispatch(closeModal());
				setShowFileSizeAlert(true);
				dispatch(setRerender());
			}
		}
	}, [supportingDocumentAnalyzeResponse]);

	// Show alerts regarding expense update
	useEffect(() => {
		if (expenseUpdateResponse) {
			setLoading(true);
			if (expenseUpdateResponse.status === 200) {
				setShowSuccessAlert(true);
			} else if (expenseUpdateResponse.status === 422) {
				expenseDelete(expenseId);
				setShowFileSizeAlert(true);
			} else {
				setShowAnalysisErrorAlert(true);
			}
			dispatch(closeModal());
			dispatch(startLoader({}));
			setTimeout(() => {
				navigate("/expenses");
				dispatch(setRerender());
			}, 2000);
		}
	}, [expenseUpdateResponse]);

	// Disable the save button where receipt is not set
	const isReceiptUploaded = receipt !== null;

	return (
		<>
			<div
				onClick={handleOpenModal}
				className="hover:text-primary-500 hover:cursor-pointer"
			>
				{children}
			</div>
			{modal.isOpen && modal.key === "modalReceiptSubmit" && (
				<Modal
					modalSubmit={modalSubmit}
					isDisabled={!isReceiptUploaded || loading}
				>
					{!loading && <ReceiptUploadForm onChange={uploadReceiptImage} />}
					{loading && (
						<div className="flex items-center justify-center">
							<FontAwesomeIcon
								icon={faSpinner}
								spin
								className="text-blue-500 text-4xl"
							/>
							<span className="ml-2">Loading...</span>
						</div>
					)}
				</Modal>
			)}
			{showSuccessAlert && (
				<Alert
					message="Expense created successfully."
					type="success"
					duration={5000}
				/>
			)}
			{showFileSizeAlert && (
				<Alert
					message="File size exceeds the limit. Please edit manually."
					type="error"
					duration={5000}
				/>
			)}
			{showAnalysisErrorAlert && (
				<Alert
					message="Some fields may not be updated. Please check the expense."
					type="warning"
					duration={5000}
				/>
			)}
			{showUnsupportedFileTypeAlert && (
				<Alert
					message="Unsupported file type. Please upload a JPEG or PNG image."
					type="error"
					duration={5000}
				/>
			)}
		</>
	);
}
