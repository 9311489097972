import "./App.css";
import { Route, Routes } from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/authPages/LoginPage";
import RegisterPage from "./pages/authPages/RegisterPage";
import Layout from "./components/layout/Layout";
import axios from "axios";
import { UserContextProvider } from "./context/UserContext";
import AccountPage from "./pages/userPages/AccountPage";
import ExpensesPage from "./pages/userPages/ExpensesPage";
import SettingsPage from "./pages/userPages/SettingsPage";
import Protected from "./components/layout/Protected";
import ReportsPage from "./pages/ReportsPage";
import ReportPage from "./pages/ReportPage";
import InviteUserPage from "./pages/InviteUserPage";
import HelpPage from "./pages/HelpPage";
import ResetPasswordRequestPage from "./pages/ResetPasswordRequestPage";
import ResetPasswordPage from './pages/ResetPasswordPage';
import ArticlePage from "./pages/ArticlePage";
import ErrorPage from "./pages/ErrorPage";

const bearerToken = process.env.REACT_APP_BEARER_TOKEN;
axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/api/";

function App() {
	if (!localStorage.getItem("token")) {
		axios.defaults.headers.common["Authorization"] = "Bearer " + bearerToken;
	} else {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + localStorage.getItem("token");
	}

	const routes = [
		{ path: "/login", element: <LoginPage /> },
		{ path: "/register", element: <RegisterPage /> },
		{ path: "/register/:uuid", element: <InviteUserPage /> },
		{ path: "/reset", element: <ResetPasswordRequestPage /> },
		{ path: "/reset/:uuid", element: <ResetPasswordPage /> },
	];

	// Define routes with conditional rendering for authentication
	const protected_routes = [
		{ path: "/", element: <Protected element={<IndexPage />} /> },
		{ path: "/account", element: <Protected element={<AccountPage />} /> },
		{ path: "/expenses", element: <Protected element={<ExpensesPage />} /> },
		{ path: "/settings", element: <Protected element={<SettingsPage />} /> },
		{ path: "/reports", element: <Protected element={<ReportsPage />} /> },
		{ path: "/report/:id", element: <Protected element={<ReportPage />} /> },
		{ path: "/help", element: <Protected element={<HelpPage />} /> },
		{ path: "/article/title/:title", element: <Protected element={<ArticlePage />} /> },
	];

	return (
		<UserContextProvider>
			<Routes>
				<Route path="/" element={<Layout />}>
					{protected_routes.map((route, index) => (
						<Route
							key={index}
							{...route}
						/>
					))}
					<Route path="*" element={<ErrorPage />} />
				</Route>
				{routes.map((route, index) => (
					<Route
						key={index}
						{...route}
					/>
				))}
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</UserContextProvider>
	);
}

export default App;
