import Price from "./Price";
import StatePill from "./StatePill";
import Receipt from "./Receipt";
import { useEffect, useState } from "react";
import useExpenseUpdate from "../../actions/hooks/useExpenseUpdate";
import Avatar from "../../components/user/Avatar";
import FormattedDate from "../elements/FormattedDate";

const ExpenseItem = ({ expense, onCheckChange, onClick }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [editableDescription, setEditableDescription] = useState(
    expense.description
  );

  useEffect(() => {
    setEditableDescription(expense.description);
  }, [expense.description]);

  const updatedExpense = { ...expense, description: editableDescription };

  const handleDescriptionChange = (e) => {
    setEditableDescription(e.target.value);
  };

  const { expenseUpdate, expenseUpdateResponse } = useExpenseUpdate();
  const submitDescriptionChange = () => {
    expenseUpdate(expense.id, updatedExpense);
  };
  useEffect(() => {
    onCheckChange();
  }, [expenseUpdateResponse]);

  return (
    <div
      onClick={onClick}
      className="flex flex-wrap justify-between items-center gap-2"
    >
      <div className="flex flex-col md:flex-row items-start md:items-center max-w-40 min-w-40 gap-6">
        <div className="max-w-40 min-w-40">
          <FormattedDate date={expense.date} showIcon={true} />
        </div>

        <div className="flex flex-col gap-2">
          <div>
            <span className="text-sm line-clamp-2">
              {expense.merchant ? (
                <span className="text-sm font-semibold">
                  {expense.merchant.name && expense.merchant.name.length > 25
                    ? `${expense.merchant.name.slice(0, 25)}...`
                    : expense.merchant.name}
                </span>
              ) : (
                <span className="text-sm font-semibold">Unknown merchant</span>
              )}
            </span>
          </div>
          <div>
            <StatePill size="sm" state={expense.state} />
          </div>
          <div>
            <Price price={expense.price} />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col md:pl-4 hidden lg:inline-block">
          <span className="text-sm">Description</span>
          <div
            className={`flex w-full max-w-full md:max-w-52 md:min-w-52 max-h-16 min-h-16 overflow-hidden border rounded-lg ${isFocused ? "border-secondary" : "border-gray-300"
              }`}
            onClick={(e) => {
              e.stopPropagation();
              setIsFocused(true);
            }}
          >
            <textarea
              className="text-sm text-gray-600 w-full border-none outline-none px-2 resize-none max-h-16 overflow-hidden"
              value={editableDescription || ""}
              onChange={handleDescriptionChange}
              onBlur={() => {
                submitDescriptionChange();
                setIsFocused(false);
              }}
              maxLength={255}
              rows={4}
            />
          </div>
        </div>
      </div>
      <div className="max-w-16">
        {expense && expense.supporting_documents[0] ? (
          <Receipt src={expense.supporting_documents[0].file_path} />
        ) : (
          <Receipt />
        )}
      </div>
    </div>
  );
};

export default ExpenseItem;
