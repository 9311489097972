import PageTitle from "./PageTitle";
import Container from "../components/layout/Container";
import Card from "../components/elements/Card";
import ReportsOverviewShort from "../components/reports/ReportsOverviewShort";
import ReportsGraph from "../components/reports/ReportsGraph";
import useFetchUserReports from "../actions/hooks/useFetchUserReports";
import ReportsListShort from "../components/reports/ReportsListShort";
import ExpenseCreateOptions from "../components/expenses/ExpenseCreateOptions";
export default function IndexPage() {
	const reportsResponse = useFetchUserReports();

	return (
		<>
			<div>
				<PageTitle title="Home" />
			</div>
			<div>
				<Container>
					<div className="flex items-center justify-between">
						<div className="ml-auto">
							<ExpenseCreateOptions />
						</div>
					</div>
					<div className="border-t border-gray-300 pb-2"></div>
					<div className="flex flex-col gap-4">
						<div className="flex flex-col lg:flex-row justify-between gap-4">
							<div className="w-full lg:w-3/6 flex-1">
								<Card header="My Reports">
									{reportsResponse && reportsResponse.data.reports && (
										<ReportsOverviewShort
											reports={reportsResponse.data.reports}
										/>
									)}
								</Card>
							</div>
							<div className="w-full lg:w-3/6 ">
								<Card header="Reports requiring attention">
									{reportsResponse && reportsResponse.data.reports && (
										<ReportsListShort reports={reportsResponse.data.reports} />
									)}
								</Card>
							</div>
						</div>
						<div className="flex flex-col lg:flex-row justify-between gap-4">
							<div className="w-full lg:w-3/6 flex-1">
								<Card header="Reports by date">
									{reportsResponse && (
										<ReportsGraph
											reports={reportsResponse.data.reports}
											graphType="reports"
										/>
									)}
								</Card>
							</div>
							<div className="w-full lg:w-3/6 ">
								<Card header="Expenses by date">
									{reportsResponse && (
										<ReportsGraph
											reports={reportsResponse.data.reports}
											graphType="euros"
										/>
									)}
								</Card>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	);
}
