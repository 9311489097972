import axios from "axios";
import { useState, useEffect } from "react";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useFetchArticleByTitle = (title) => {
	const [article, setArticle] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchArticle = async () => {
			dispatch(startLoader({}));
			try {
				const response = await axios.get(`/article/title/${title}`);
				setArticle(response.data.articles[0]);
			} finally {
				dispatch(stopLoader({}));
			}
		};

		fetchArticle();
	}, [title]);

	return { article };
};

export default useFetchArticleByTitle;