import Modal from "../elements/Modal";
import ExpenseForm from "./ExpenseForm";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../actions/modalActions";
import { setExpense } from "../../actions/expenseAction";
import { useEffect } from "react";
import Alert from "../elements/Alert";
import useShowAlert from "../../actions/hooks/utility/useShowAlert";
import useExpenseCreateUpdate from "../../actions/hooks/utility/useExpenseCreateUpdate";
import useSupportingDocumentUpdate from "../../actions/hooks/useSupportingDocumentUpdate";
import { setRerender } from "../../actions/rerenderAction";
import { useNavigate } from "react-router-dom";
import { startLoader } from "../../actions/loaderActions";

export default function ExpenseCreateOptionManually({ children }) {
	// Handle modal
	const dispatch = useDispatch();
	const handleOpenModal = () => {
		dispatch(setExpense({}));
		dispatch(
			openModal({
				modalType: "static",
				modalTitle: "New Expense",
				modalSubmit: "SUBMIT_EXPENSE",
				key: "modalExpenseSubmit",
				needsDelete: false,
			})
		);
	};
	const modal = useSelector((store) => store.modal);
	const exp = useSelector((store) => store.expense);

	const {
		showSuccessAlert,
		setShowSuccessAlert,
		showErrorAlert,
		setShowErrorAlert,
	} = useShowAlert();

	const { expenseCreateUpdate, expenseCreateUpdateResponse } = useExpenseCreateUpdate();
	const { supportingDocumentUpdate } = useSupportingDocumentUpdate();

	const modalSubmit = () => {
		expenseCreateUpdate();
		dispatch(closeModal({}));
	};

	const navigate = useNavigate();
	useEffect(() => {
		if (expenseCreateUpdateResponse) {
			if (expenseCreateUpdateResponse.status === 200) {

				// Get the expense ID from the response
				const expenseId = expenseCreateUpdateResponse.data?.expense?.id;

				// Get the supporting document ID
				const supportingDocumentId = exp?.supporting_documents[0]?.id;

				// If the expense and supporting document IDs are available, update the supporting document
				if (expenseId && supportingDocumentId)
					supportingDocumentUpdate(supportingDocumentId, { expense_id: expenseId });

				setShowSuccessAlert(true);
				dispatch(startLoader({}));
				setTimeout(() => {
					navigate("/expenses");
					dispatch(setRerender());
				}, 2000);
			} else {
				setShowErrorAlert(true);
			}
		}
	}, [expenseCreateUpdateResponse]);

	return (
		<>
			<div
				onClick={handleOpenModal}
				className="hover:text-primary-500 hover:cursor-pointer"
			>
				{children}

				{showErrorAlert && (
					<Alert message="Failed to create expense." type="error" duration={2000} />
				)}
				{showSuccessAlert && (
					<Alert message="Expense created successfully." type="success" duration={2000} />
				)}
			</div>
			{modal.isOpen && modal.key === "modalExpenseSubmit" && (
				<Modal modalSubmit={modalSubmit} type="expenseForm">
					<ExpenseForm />
				</Modal>
			)}
		</>
	);
}
