import axios from "axios";
import { useState } from "react";

const useSupportingDocumentAnalyze = () => {
  // Variables
  const [
    supportingDocumentAnalyzeResponse,
    setSupportingDocumentAnalyzeResponse,
  ] = useState(null);

  // Analyze receipt to get the information
  const supportingDocumentAnalyze = async (receipt_id) => {
    try {
      const response = await axios.post("supporting-document/analyze/" + receipt_id);
      setSupportingDocumentAnalyzeResponse(response);
    } catch (error) {
      const status = error.response?.status || 500;
      setSupportingDocumentAnalyzeResponse({
        status,
      });
    }
  };

  return { supportingDocumentAnalyze, supportingDocumentAnalyzeResponse };
};

export default useSupportingDocumentAnalyze;
