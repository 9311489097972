import { useParams } from "react-router-dom";
import Container from "../components/layout/Container";
import useFetchArticleByTitle from "../actions/hooks/useFetchArticleByTitle";

const ArticlePage = () => {
    const { title } = useParams();
    const { article } = useFetchArticleByTitle(title);

    return (
        <Container>
            {article && (
                <>
                    <div className="text-2xl font-semibold">{article.title.replace(/_/g, ' ')}</div>
                    <div className="bg-white rounded-lg p-4 text-gray-700">
                        <div dangerouslySetInnerHTML={{ __html: article.body }} />
                    </div>
                </>
            )}
        </Container>
    );
};

export default ArticlePage;
